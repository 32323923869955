import { defineStore } from 'pinia'
export const useSubsidyTypesStore = defineStore('subsidyTypesStore', {
  state: () => ({
    subsidyTypes: [
      {
        name: 'PRODUCTION',
        nameDe: 'Verkehrsmilch',
        nameFr: 'Lait commercialisé',
        nameIt: 'Latte commerzializzato'
      },
      {
        name: 'PROCESSING',
        nameDe: 'Verkäsung/Silofrei',
        nameFr: 'Fromage/sans ensilage',
        nameIt: 'Formaggio/senza insilati'
      }
    ]
  })
})
