<template>
  <v-container>
    <v-row>
      <v-col>
        <SubsidyConfigs />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SubsidyInvoiceRuns />
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import SubsidyConfigs from './editSubsidyConfigs'
import SubsidyInvoiceRuns from './editSubsidyInvoiceRuns'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SubsidyAdmin',
  computed: {},
  methods: {},
  components: {
    SubsidyConfigs: SubsidyConfigs,
    SubsidyInvoiceRuns: SubsidyInvoiceRuns
  }
})
</script>
