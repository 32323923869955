<template>
  <div>
    <h1>
      <span v-html="$t('subsidyInvoiceRuns_title')" />
    </h1>
    <v-row>
      <v-col cols="12" lg="6">
        <v-select
          v-model="subsidyType"
          :label="$t('subsidyInvoiceRuns_search_subsidyType')"
          :items="subsidyTypes"
          :item-title="this.$getLangKey()"
          item-value="name"
          clearable
          @change="getSubsidyInvoiceRuns"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span v-html="$t('subsidyInvoiceRuns_info')" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <v-btn color="primary shadow-none" class="button-margin" @click="fillupRuns"><span v-html="$t('subsidyInvoiceRuns_fillup')" /></v-btn>
      </v-col>
      <v-col cols="6" lg="3" v-if="subsidyType">
        <DbmDatePicker v-model="scheduled" :dateLabel="$t('subsidyInvoiceRuns_edit_scheduled')" :clearable="true" />
      </v-col>
      <v-col cols="6" lg="3" v-if="subsidyType">
        <v-btn color="primary shadow-none" class="button-margin" :disabled="!scheduled" @click="addRun"
          ><span v-html="$t('subsidyInvoiceRuns_addRun')"
        /></v-btn>
      </v-col>
    </v-row>
    <v-data-table-server
      :headers="fields"
      :fields="fields"
      :items="subsidyInvoiceRuns"
      :loading="loading"
      density="compact"
      :multi-sort="true"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      :items-length="totalElements"
    >
      <template v-slot:[`item.scheduled`]="{ item }">{{ $moment(item.scheduled).format('DD.MM.YYYY') }}</template>
      <template v-slot:[`item.active`]="{ item }">
        <v-switch v-model="item.active" :label="item.active ? $t('yes') : $t('no')" @click="saveRun(item)" color="primary"></v-switch>
      </template>
    </v-data-table-server>
  </div>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { DTSHeaders } from '@/services/BackendService'
import { useSubsidyTypesStore } from '@/store/enums/SubsidyTypesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SubsidyInvoiceRuns',
  data() {
    return {
      subsidyType: null,
      scheduled: null,
      subsidyInvoiceRuns: [],
      loading: false,
      totalElements: 0,
      fields: [
        { key: 'scheduled', title: this.$t('subsidyInvoiceRuns_tableHeader_scheduled'), sortable: true, align: 'start' },
        { key: 'subsidyTypeDe', title: this.$t('subsidyInvoiceRuns_tableHeader_subsidyType'), sortable: false, align: 'start' },
        { key: 'active', title: this.$t('subsidyInvoiceRuns_tableHeader_active'), sortable: false, align: 'start' }
      ] as DTSHeaders
    }
  },
  computed: {
    subsidyTypes() {
      return useSubsidyTypesStore().subsidyType
    },
    query(): any {
      return this.getJavaPageOptions({ sort: ['scheduled,desc'] })
    }
  },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  methods: {
    async load() {
      this.loading = true
      let url = apiURL + '/subsidyInvoiceRuns/find'
      if (this.subsidyType) {
        url += '?subsidyType=' + this.subsidyType
      }
      try {
        const response = await this.axios.get(url, {
          params: this.query
        })
        this.subsidyInvoiceRuns = response.data.content
        this.totalElements = this.checkPage(response.data.totalElements)
        this.loading = false
      } catch (e) {
        showError(e)
      }
    },
    async fillupRuns() {
      try {
        await this.axios.get(apiURL + '/subsidyInvoiceRuns/fillup')
        this.load()
      } catch (e) {
        showError(e)
      }
    },
    async addRun() {
      try {
        await this.axios.post(apiURL + '/subsidyInvoiceRuns', {
          subsidyType: this.subsidyType,
          scheduled: this.scheduled,
          active: true
        })
        this.scheduled = null
        this.load()
      } catch (e) {
        showError(e)
      }
    },
    async saveRun(item: any) {
      try {
        await this.axios.put(apiURL + '/subsidyInvoiceRuns/' + item.id, {
          id: item.id,
          subsidyType: item.subsidyType,
          scheduled: item.scheduled,
          active: item.active
        })
      } catch (e) {
        showError(e)
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.load()
    })
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue, oldValue) {
        this.load()
      },
      deep: true
    }
  }
})
</script>
